<script>
</script>

<footer>
  <nav class="menu">
    <span class="menu__item active">
      <span class="material-icons"> login </span>
      入所
    </span>
    <span class="menu__item">
      <span class="material-icons"> logout </span>
      退所
    </span>
    <span class="menu__item">
      <span class="material-icons"> search </span>
      照会
    </span>
  </nav>
</footer>

<style lang="scss">
  footer {
    border-top: 1px solid #c7c7c7;
    padding: 4px 32px;
    pointer-events: none;
    touch-action: manipulation;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu__item {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    font-size: 12px;
    text-decoration: none;
    height: 48px;
    min-height: 48px;
  }
  .menu__item.active {
    color: #4395f5;
    font-weight: bold;
  }
  .menu__item .material-icons {
    margin-bottom: 4px;
  }
</style>
