<script>
  export let name = "";
  /**
   * value: string
   * label: string
   * checked: boolean
   */
  export let items = [];
  export let color = "";
</script>

<div class="radio-area">
  {#each items as item}
    <div class="radio">
      <input
        type="radio"
        {name}
        value={item.value}
        checked={item.checked}
        id="{name}-{item.value}"
      />
      <label for="{name}-{item.value}">
        <span class="radio__button {color}"></span>
        <span class="radio__text">{item.label}</span>
      </label>
    </div>
  {/each}
</div>

<style>
  .radio-area {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    margin-top: 12px;
  }
  .radio {
    display: inline-block;
  }
  .radio:not(:first-of-type) {
    margin-top: 8px;
  }
  input[type="radio"] {
    /*appearance: none;*/
    display: none;
  }
  .radio label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }
  .radio__button {
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 50%;
    color: #333;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    height: 24px;
    width: 24px;
  }
  .radio__button.red {
    border-color: #f00;
  }
  input[type="radio"]:checked + label .radio__button::after {
    content: "";
    background-color: #333;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
  }
  input[type="radio"]:checked + label .radio__button.red::after {
    background-color: #f00;
  }
  .radio__text {
    display: inline-block;
    max-width: calc(100% - 32px);
  }
</style>
