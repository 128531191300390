<script>
  let className = $$restProps.class ?? "";
  let disabledFlag = $$restProps.disabled ?? "";
</script>

<button type="button" class={className} disabled={disabledFlag} on:click>
  <slot />
</button>

<style>
  button {
    background-color: #fff;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    font-size: inherit;
    font-weight: bold;
    padding: 16px 24px;
    min-width: 200px;
  }
  button:active {
    box-shadow: none;
    transform: translateY(2px);
  }
  button:disabled {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transform: translateY(0);
  }
  .primary {
    background-color: #d9d9d9;
  }
</style>
