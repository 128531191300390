<script>
  import { onDestroy } from "svelte";
  import { push } from "svelte-spa-router";

  import sceneImageScanQrCode from "~/assets/images/read-qr.png";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import { beep, destroyAudioContext } from "~/libs/audio";
  import Button from "~/pages/common/Button.svelte";

  /** @type {QrCodeScanner} */
  let qrCodeScanner;

  /** QRコードをスキャン中か否か */
  let qrCodeScanInProgress = false;

  onDestroy(() => {
    setTimeout(destroyAudioContext, 600);
  });

  /**
   * @param {string} decodedText
   */
  async function onScanSuccess(decodedText) {
    console.log("QrCodeScanner onScanSuccess:", decodedText);
    if (decodedText.startsWith("SHELTERDX$MOCKUP$")) {
      const mockId = decodedText.substring(17);
      if (mockId.length == 10) {
        beep();
        push("/result/" + mockId);
      }
    }
  }
</script>

{#if !qrCodeScanInProgress}
  <img
    src={sceneImageScanQrCode}
    alt="QRコードを読み込んでいるイメージ画像"
    class="scan-image"
  />

  <div class="startScanButtonWrapper">
    <Button
      class="primary"
      on:click={async () => {
        await qrCodeScanner.startScanning();
        qrCodeScanInProgress = true;
      }}
    >
      カメラを起動してQRコードをスキャン
    </Button>
  </div>
{/if}

<QrCodeScanner bind:this={qrCodeScanner} onScanSuccessHandler={onScanSuccess} />

<style lang="scss">
  .scan-image {
    margin-top: 16px;
    width: 100%;
  }

  .startScanButtonWrapper {
    margin-top: 15px;
    text-align: center;

    :global(button) {
      letter-spacing: -0.075em;
    }
  }
</style>
