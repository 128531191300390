<script>
  import { push } from "svelte-spa-router";

  import sceneImageScanNfcTag from "~/assets/images/read-nfc.png";

  // NFCタグの読み取り
  if ("NDEFReader" in window) {
    (async () => {
      // @ts-ignore
      const ndefReader = new window.NDEFReader();
      ndefReader.addEventListener("reading", ({ message, serialNumber }) => {
        console.log("NDEFReader onReading:", serialNumber, message);
        push("/result/" + serialNumber);
      });
      await ndefReader.scan();
    })();
  }
</script>

<img
  src={sceneImageScanNfcTag}
  alt="NFCタグを読み込んでいるイメージ画像"
  class="scan-image"
/>

<style>
  .scan-image {
    margin-top: 16px;
    width: 100%;
  }
</style>
