<script>
  export let open = false;

  import { slide } from "svelte/transition";
</script>

<div class="accordion">
  <button
    class="summary {open ? 'open' : ''}"
    on:click={() => {
      open = !open;
    }}
  >
    <slot name="summary" />
  </button>
  {#if open}
    <div class="details" transition:slide>
      <slot name="details" />
    </div>
  {/if}
</div>

<style>
  .accordion {
    width: 100%;
  }
  .summary {
    background-color: #ededed;
    border: 1px solid #a0a0a0;
    font-size: 18px;
    font-weight: bold;
    padding: 6px 40px 6px 8px;
    position: relative;
    text-align: left;
    width: 100%;
  }
  .summary::before,
  .summary::after {
    content: "";
    background-color: #a0a0a0;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.5s;
    height: 3px;
    width: 17px;
  }
  .summary::after {
    transform: translateY(-50%) rotate(-90deg);
  }
  .summary.open::after {
    transform: translateY(-50%);
  }
</style>
