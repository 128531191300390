<script>
  export let color = "";

  const SpeechRecognition =
    // @ts-ignore
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  recognition.continuous = true;
  recognition.lang = "ja-JP";
  recognition.interimResults = true;

  const textarea = document.getElementsByTagName("textarea")[0];

  recognition.onresult = (event) => {
    textarea.value = event.results[0][0].transcript;
  };

  function startMic() {
    recognition.start();
  }
</script>

<label class={color}>
  <slot name="label" />
  <div class="textarea">
    <textarea rows="8" id="textarea" />
    <button on:click={startMic}>
      <span class="material-icons gray"> keyboard_voice </span>
    </button>
  </div>
</label>

<style>
  label.red {
    color: #f00;
  }
  .textarea {
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 4px;
    display: block;
    margin-top: 4px;
    outline: none;
    padding-bottom: 32px;
    position: relative;
    width: 100%;
  }
  label.red .textarea {
    border-color: #f00;
  }
  textarea {
    border: none;
    font-size: 16px;
    outline: none;
    padding: 8px;
    width: 100%;
  }
  button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
  .material-icons.gray {
    color: #808080;
  }
</style>
