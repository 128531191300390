export let personalInfo = [
  {
    header: "名前",
    value: "山田 太郎",
  },
  {
    header: "フリガナ",
    value: "ヤマダ タロウ",
  },
  {
    header: "生年月日",
    value: "2003年10月10日 (20歳)",
  },
  {
    header: "性別",
    value: "男",
  },
  {
    header: "住所",
    value: "東京都中野区中野X-X-X",
  },
  {
    header: "電話番号",
    value: "000-0000-0000",
  },
  {
    header: "避難履歴",
    value: "なし",
  },
];

let familyInfo1 = [
  {
    header: "フリガナ",
    value: "ヤマダ ジロウ",
  },
  {
    header: "生年月日",
    value: "2005年10月10日 (18歳)",
  },
  {
    header: "性別",
    value: "男",
  },
  {
    header: "住所",
    value: "東京都中野区中野X-X-X",
  },
  {
    header: "電話番号",
    value: "000-0000-0000",
  },
  {
    header: "避難履歴",
    value: "なし",
  },
];

let familyInfo2 = [
  {
    header: "フリガナ",
    value: "ヤマダ サブロウ",
  },
  {
    header: "生年月日",
    value: "2007年10月10日 (16歳)",
  },
  {
    header: "性別",
    value: "男",
  },
  {
    header: "住所",
    value: "東京都中野区中野X-X-X",
  },
  {
    header: "電話番号",
    value: "000-0000-0000",
  },
  {
    header: "避難履歴",
    value: "なし",
  },
];

export let familyInfo = [
  {
    name: "山田 次郎（18歳 男）",
    info: familyInfo1,
  },
  {
    name: "山田 三郎（16歳 男）",
    info: familyInfo2,
  },
];
