<script>
  import { fade } from "svelte/transition";
  import { pop, push } from "svelte-spa-router";

  import Button from "~/pages/common/Button.svelte";
  import Footer from "~/pages/common/Footer.svelte";
  import Header from "~/pages/common/Header.svelte";
  import Tab from "~/pages/common/Tab.svelte";
  import Tab1 from "~/pages/NfcTagScan2/Tab1.svelte";
  import Tab2 from "~/pages/NfcTagScan2/Tab2.svelte";

  /** @type {{mockId: string}} */
  export let params;

  console.log(params.mockId);

  let tabItems = [
    {
      label: "基本情報",
      component: Tab1,
    },
    {
      label: "要配慮情報",
      component: Tab2,
    },
  ];

  let displayTabCheck = true;
  let confirmationFlag = false;
</script>

<div class="mainContentsWrapper">
  <Header />
  <main in:fade>
    <div class="tab-area">
      <Tab
        items={tabItems}
        {displayTabCheck}
        on:checked={() => (confirmationFlag = true)}
      />
    </div>
    <div class="button-area">
      {#if !confirmationFlag}
        <p class="caution">要配慮事項も確認してください</p>
        <Button class="primary" disabled="disabled">入所する</Button>
      {:else}
        <Button
          class="primary"
          on:click={() => {
            push("/complete");
          }}
        >
          入所する
        </Button>
      {/if}
      <Button
        on:click={() => {
          pop();
        }}
      >
        戻る
      </Button>
    </div>
  </main>
  <Footer />
</div>

<style>
  main {
    padding: 24px 16px 80px;
    height: 100%;
    width: 100%;
  }
  .button-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    row-gap: 24px;
    margin-top: 64px;
    text-align: center;
  }
  .caution {
    color: #f00;
    font-weight: bold;
  }
</style>
