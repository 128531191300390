import cvDetectCaffeModel from "~/libs/opencv-js-qrcode/models/detect.caffemodel?url";
import cvDetectProtoTxt from "~/libs/opencv-js-qrcode/models/detect.prototxt?url";
import cvSRCaffeModel from "~/libs/opencv-js-qrcode/models/sr.caffemodel?url";
import cvSRProtoTxt from "~/libs/opencv-js-qrcode/models/sr.prototxt?url";
import cvModule from "~/libs/opencv-js-qrcode/opencv-es6-min.js?url";
import cvWasmBinary from "~/libs/opencv-js-qrcode/opencv_js.wasm?url";

/**
 * OpenCV object
 * @type {{ FS_createDataFile: (parent: string, name: string, data: any, canRead: boolean, canWrite: boolean, canOwn: boolean) => void;
 *          wechat_qrcode_WeChatQRCode: new (...modelNames: Array<string>) => any;
 *          imread: (imageSource: string | HTMLImageElement | HTMLCanvasElement | OffscreenCanvas) => any; }}
 */
export let cv;

/**
 * WeChat QR code detector
 * @type {{ detectAndDecode: (matImage: any) => any; }}
 */
export let qrcodeDetector;

/**
 * Initialize cv and qrcodeDetector.
 */
export async function initCV() {
  if (!cv) {
    // dynamic import opencv.js
    const cvPromise = (await import(cvModule)).default({
      locateFile: () => cvWasmBinary,
    });

    const models = [
      { name: "detect.prototxt", path: cvDetectProtoTxt },
      { name: "detect.caffemodel", path: cvDetectCaffeModel },
      { name: "sr.prototxt", path: cvSRProtoTxt },
      { name: "sr.caffemodel", path: cvSRCaffeModel },
    ];

    // fetch models
    await Promise.all(
      models.map(async (model) => {
        const response = await fetch(model.path);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch model: status=${response.status}, url=${response.url}`,
          );
        }
        model.data = new Uint8Array(await response.arrayBuffer());
      }),
    );

    // initialize OpenCV object w/ fetch and load WASM binary
    cv = await cvPromise;

    // load models
    models.forEach((model) => {
      cv.FS_createDataFile("/", model.name, model.data, true, false, false);
    });

    // initialize WeChat QR code detector
    qrcodeDetector = new cv.wechat_qrcode_WeChatQRCode(
      ...models.map((model) => model.name),
    );

    console.log("OpenCV initialized:", cv, qrcodeDetector);
  }
}
