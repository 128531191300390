<script>
  import { fade } from "svelte/transition";
  import { pop } from "svelte-spa-router";

  import Button from "~/pages/common/Button.svelte";
  import Footer from "~/pages/common/Footer.svelte";
  import Header from "~/pages/common/Header.svelte";
</script>

<div class="mainContentsWrapper">
  <Header />
  <main in:fade>
    <p>入所登録を行いました。</p>
    <p>入所者を施設内に案内してください。</p>
    <div class="button-area">
      <Button
        class="primary"
        on:click={() => {
          pop();
          pop();
        }}
      >
        読み込み画面へ戻る
      </Button>
    </div>
  </main>
  <Footer />
</div>

<style>
  main {
    padding: 24px 16px 80px;
    height: 100%;
    width: 100%;
  }
  p + p {
    margin-top: 12px;
  }
  .button-area {
    margin-top: 64px;
    text-align: center;
  }
</style>
