<script>
  import Accordion from "~/pages/common/Accordion.svelte";
  import Checkbox from "~/pages/common/Checkbox.svelte";
  import Table from "~/pages/common/Table.svelte";
  import { familyInfo, personalInfo } from "~/pages/NfcTagScan2/tableData.js";
</script>

<section>
  <h2>本人情報</h2>
  <Table data={personalInfo} />
</section>
<section>
  <h2>同居人情報</h2>
  <p>同時入所する同居人を選択してください。</p>
  <ul class="family-list">
    {#each familyInfo as person}
      <li>
        <!--<input type="checkbox" />-->
        <div class="col">
          <Checkbox />
        </div>
        <div class="col">
          <Accordion>
            <span slot="summary">{person.name}</span>
            <div slot="details">
              <div class="accordion__details">
                <Table data={person.info} />
              </div>
            </div>
          </Accordion>
        </div>
      </li>
    {/each}
  </ul>
</section>

<style>
  section:not(:first-of-type) {
    margin-top: 32px;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
    margin-bottom: 12px;
    position: relative;
  }
  h2::before {
    content: "";
    background-color: #d9d9d9;
    display: block;
    position: absolute;
    left: -10px;
    height: 100%;
    width: 6px;
  }
  .family-list {
    margin-top: 16px;
  }
  .family-list li {
    display: flex;
    align-items: flex-start;
    list-style: none;
  }
  .family-list li:not(:first-of-type) {
    margin-top: 16px;
  }
  /*
  .family-list li input[type="checkbox"] {
    block-size: 1.5rem;
    inline-size: 1.5rem;
    margin: 8px;
  }*/
  .family-list li .col:first-of-type {
    padding: 8px;
  }
  .family-list li .col:last-of-type {
    width: calc(100% - 40px);
  }
  .accordion__details {
    margin-top: 8px;
  }
</style>
