<header>
  <h1>○○小学校 体育館</h1>
</header>

<style lang="scss">
  header {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    pointer-events: none;
    touch-action: manipulation;
    height: 50px;
    min-height: 50px;
    width: 100%;
    z-index: 1;
  }
  h1 {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
  }
</style>
