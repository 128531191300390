<script>
  export let checked = false;
  export let color = "";
</script>

<label class="checkbox">
  <input type="checkbox" {checked} />
  <div class="checkbox__box {color}">
    <span class="material-icons"> check </span>
  </div>
  <div class="checkbox__text">
    <slot name="text" />
  </div>
</label>

<style>
  .checkbox {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }
  input[type="checkbox"] {
    /*appearance: none;*/
    display: none;
  }
  .checkbox__box {
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 4px;
    color: #333;
    margin-right: 8px;
    position: relative;
    height: 24px;
    width: 24px;
  }
  .checkbox__box.red {
    border-color: #f00;
    color: #f00;
  }
  .checkbox__box .material-icons {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input[type="checkbox"]:checked + .checkbox__box .material-icons {
    display: inline;
  }
  .checkbox__text {
    max-width: calc(100% - 32px);
  }
</style>
