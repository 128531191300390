<script>
  import IconButton from "@smui/icon-button";
  import Paper, { Content, Title } from "@smui/paper";
  import { onDestroy } from "svelte";

  import { OpenCvQrCodeScanner } from "~/libs/qrCodeScanner";

  /**
   * @type {import("~/libs/qrCodeScanner").onScanSuccessCallback}
   */
  export let onScanSuccessHandler;

  /**
   * @type {import("~/libs/qrCodeScanner").onVideoResizedCallback}
   */
  export let onVideoResizedHandler = () => {};

  /** @type {OpenCvQrCodeScanner} */
  const qrCodeScanner = new OpenCvQrCodeScanner(
    onScanSuccessHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  );

  /** @type {boolean} */
  let paused = qrCodeScanner.paused;
  /** @type {boolean} */
  let useBackCamera = true;

  /** @type {Promise<void>} QRコードスキャナ初期化処理（onMount前） */
  const qrCodeScannerInitBeforeMountPromise = qrCodeScanner.initBeforeMount();

  /** QRコードスキャナ初期化処理（onMount後） */
  const qrCodeScannerInitAfterMount = (() => {
    let initialized = false;
    return () => {
      if (!initialized) {
        initialized = true;
        qrCodeScanner.initAfterMount();
      }
    };
  })();

  onDestroy(() => {
    stopScanning();
  });

  export async function startScanning() {
    try {
      await qrCodeScannerInitBeforeMountPromise;
      qrCodeScannerInitAfterMount();
      await qrCodeScanner.startScanning(useBackCamera);
    } catch (error) {
      console.error(error);
      window.alert("カメラの起動に失敗しました。" + error.message);
    }
  }

  export async function stopScanning() {
    await qrCodeScanner.stopScanning();
  }

  /**
   * QRコードの読み取りを一時停止または再開します。
   * @param {boolean} pause true：一時停止、false：再開
   */
  export function pauseOrResumeScanning(pause) {
    qrCodeScanner.pauseOrResumeScanning(pause);
  }

  /**
   * @returns {OpenCvQrCodeScanner}
   */
  export function getScannerInstance() {
    return qrCodeScanner;
  }

  /**
   * @param {boolean} currentlyPaused
   */
  function onPauseStatusChangeHandler(currentlyPaused) {
    paused = currentlyPaused;
  }

  function onClickPauseAndResumeButton() {
    qrCodeScanner.pauseOrResumeScanning();
  }

  async function onClickRotateCameraButton() {
    useBackCamera = !useBackCamera;
    console.log("Rotate Camera", useBackCamera);
    await qrCodeScanner.stopScanning();
    await qrCodeScanner.startScanning(useBackCamera);
  }
</script>

<svelte:window on:orientationchange={qrCodeScanner.resizeIfNeeded} />

<div id="qrCodeScannerArea" bind:this={qrCodeScanner.qrCodeScannerArea}>
  <div class="previewRegion" bind:this={qrCodeScanner.previewRegion}>
    <video muted playsinline bind:this={qrCodeScanner.video} />
    <div class="shadedRegion" bind:this={qrCodeScanner.shadedRegion}>
      <div style="width: 40px; height: 5px; top: -5px; left: 0" />
      <div style="width: 40px; height: 5px; top: -5px; right: 0;" />
      <div style="width: 40px; height: 5px; bottom: -5px; left: 0;" />
      <div style="width: 40px; height: 5px; bottom: -5px; right: 0;" />
      <div style="width: 5px; height: 45px; top: -5px; left: -5px;" />
      <div style="width: 5px; height: 45px; bottom: -5px; left: -5px;" />
      <div style="width: 5px; height: 45px; top: -5px; right: -5px;" />
      <div style="width: 5px; height: 45px; bottom: -5px; right: -5px;" />
    </div>
    <div class="qrCodeScannerButtonArea">
      <IconButton
        class="material-icons md-light pauseAndResumeButton"
        on:click={onClickPauseAndResumeButton}
        >{paused ? "play_circle" : "pause_circle"}</IconButton
      >
      <IconButton
        class="material-icons md-light rotateCameraButton"
        on:click={onClickRotateCameraButton}>flip_camera_ios</IconButton
      >
    </div>
  </div>
</div>
<div
  id="qrCodeScannerFallbackArea"
  bind:this={qrCodeScanner.qrCodeScannerFallbackArea}
>
  <Paper variant="unelevated">
    <Title>カメラの起動エラー</Title>
    <Content>
      <p>
        QRコードの読み取りにはカメラの使用許可が必要です。確認ダイアログが表示された場合は「許可」や「続行」を選択してください。
      </p>
      <p>
        ダイアログが表示されない場合はChromeの設定でカメラへのアクセスが「拒否」や「許可しない」になっていないか確認のうえアプリケーションを再起動してお試しください。
      </p>
    </Content>
  </Paper>
</div>

<canvas bind:this={qrCodeScanner.qrCodeCanvasElement} hidden />

<style lang="scss">
  #qrCodeScannerArea {
    display: none;
    pointer-events: none;

    .previewRegion {
      position: relative;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      > video {
        display: block;
        width: 100%;
        object-fit: cover;
      }

      .shadedRegion {
        position: absolute;
        inset: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.48);
        box-sizing: border-box;

        > div {
          position: absolute;
          background-color: #fff;
        }
      }

      :global(.shadedRegion.flash > div) {
        background-color: rgb(90, 193, 56);
      }

      .qrCodeScannerButtonArea {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 10;
        pointer-events: auto;

        :global(.material-icons) {
          font-size: 36px;
        }
      }
    }

    :global(.mdc-dialog) {
      z-index: 20;
    }
  }

  #qrCodeScannerFallbackArea {
    display: none;
    padding: 5px 5px 0;
    margin: 0 auto;

    :global(.mdc-dialog__title) {
      text-align: center;
      color: #bd362f;
    }
    :global(.mdc-dialog__content p:nth-child(n + 2)) {
      margin-top: 10px;
    }
  }
</style>
