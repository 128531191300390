<script>
  /**
   * header: string
   * value: string
   */
  export let data = [];
</script>

<table>
  {#each data as item}
    <tr>
      <th>{item.header}</th>
      <td>{item.value}</td>
    </tr>
  {/each}
</table>

<style>
  table {
    width: 100%;
  }
  th,
  td {
    border: 1px solid #a0a0a0;
    padding: 6px 8px;
    text-align: left;
  }
  th {
    background-color: #ededed;
    font-size: 14px;
    font-weight: normal;
  }
  td {
    font-weight: bold;
  }
</style>
