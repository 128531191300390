<script>
  import Accordion from "~/pages/common/Accordion.svelte";
  import Checkbox from "~/pages/common/Checkbox.svelte";
  import Radio from "~/pages/common/Radio.svelte";
  import Textarea from "~/pages/common/Textarea.svelte";
  import { equipment, helper } from "~/pages/NfcTagScan2/radioData.js";
</script>

<section>
  <Accordion open={true}>
    <span slot="summary">山田 太郎（20歳 男）</span>
    <div slot="details">
      <div class="accordion__details">
        <p>足が不自由</p>
        <ul>
          <li>車いすを使用</li>
          <li>移動に介助者が必要</li>
          <li>立ち座りに介助者が必要</li>
        </ul>
      </div>
    </div>
  </Accordion>
  <section class="confirmation-items">
    <h3>確認事項</h3>
    <ul class="check-list">
      <li>
        <Checkbox color="red">
          <span slot="text">
            出入口やトイレに近い居住スペースを割り当てるなど、できるだけ移動が少なくて済むように配慮する。
          </span>
        </Checkbox>
      </li>
      <li>
        <Checkbox color="red">
          <span slot="text">
            居住スペースやトイレまでの移動経路に十分な広さが確保されていることを確認する。
          </span>
        </Checkbox>
      </li>
      <li>
        <Checkbox color="red">
          <span slot="text">
            移動経路にできるだけ段差がないように配慮する。
          </span>
        </Checkbox>
      </li>
      <li>
        <Checkbox color="red">
          <span slot="text">
            車いす対応、または十分な広さのトイレを確保する。確保が難しい場合は代替案を相談する。
          </span>
        </Checkbox>
      </li>
      <li>
        <Checkbox color="red">
          <span slot="text"> 介助者が同行しているかどうかを確認する。 </span>
        </Checkbox>
        <div class="check-list__supplement">
          <Radio name="helper" items={helper} color="red" />
        </div>
      </li>
      <li>
        <Checkbox color="red">
          <span slot="text"> 椅子や簡易ベッドの要否を確認する。 </span>
        </Checkbox>
        <div class="check-list__supplement">
          <Radio name="equipment" items={equipment} color="red" />
        </div>
      </li>
    </ul>
    <div class="others">
      <Textarea color="red">
        <span slot="label">その他・特筆事項</span>
      </Textarea>
    </div>
  </section>
</section>

<style>
  .accordion__details {
    border-left: 1px solid #a0a0a0;
    border-right: 1px solid #a0a0a0;
    border-bottom: 1px solid #a0a0a0;
    font-size: 18px;
    font-weight: bold;
    padding: 8px;
  }
  .accordion__details ul {
    margin-left: 2rem;
  }
  .confirmation-items {
    background-color: #ffe5e5;
    margin-top: 32px;
    padding: 16px;
  }
  h3 {
    font-size: 18px;
  }
  .check-list {
    margin-top: 12px;
  }
  .check-list li {
    list-style: none;
  }
  .check-list li:not(:first-of-type) {
    margin-top: 12px;
  }
  .check-list__supplement {
    margin-left: 32px;
  }
  .others {
    margin-top: 24px;
  }
</style>
