<script>
  import { fade } from "svelte/transition";
  import { push } from "svelte-spa-router";

  import Footer from "~/pages/common/Footer.svelte";
  import Header from "~/pages/common/Header.svelte";
  import Tab from "~/pages/common/Tab.svelte";
  import Tab1 from "~/pages/NfcTagScan1/Tab1.svelte";
  import Tab2 from "~/pages/NfcTagScan1/Tab2.svelte";

  let tabItems = [
    {
      label: "ICタグ",
      component: Tab1,
    },
    {
      label: "QRコード",
      component: Tab2,
    },
  ];

  let localFlag = location.href.indexOf("localhost") !== -1 ? true : false;
</script>

<div class="mainContentsWrapper">
  <Header />
  <main in:fade>
    <p>本避難所に入所される方のICタグ、またはQRコードを読み込んでください。</p>
    <div class="tab-area">
      <Tab items={tabItems} />
    </div>

    {#if localFlag}
      <button
        style="width: 50px; height: 30px;"
        on:click={() => {
          push("/result/0000000001");
        }}
      >
        次へ
      </button>
    {/if}
  </main>
  <Footer />
</div>

<style>
  main {
    padding: 24px 16px 80px;
    height: 100%;
    width: 100%;
  }
  .tab-area {
    margin-top: 20px;
  }
</style>
