import { cv, initCV, qrcodeDetector } from "~/libs/opencv-qrcode";

/**
 * @callback onScanSuccessCallback
 * @param {string} decodedText
 *
 * @callback onReadQrCodeCallback
 *
 * @callback onPauseStatusChangeCallback
 * @param {boolean} currentlyPaused
 *
 * @callback onVideoResizedCallback
 */

export class QrCodeScanner {
  /** @type {onScanSuccessCallback} */
  onScanSuccessHandler;
  /** @type {onPauseStatusChangeCallback} */
  #onPauseStatusChangeHandler;
  /** @type {onVideoResizedCallback} */
  #onVideoResizedHandler;
  /** @type {onReadQrCodeCallback} */
  onReadQrcode;

  /** @type {HTMLVideoElement} */
  video;
  /** @type {HTMLDivElement} */
  qrCodeScannerArea;
  /** @type {HTMLDivElement} */
  previewRegion;
  /** @type {HTMLDivElement} */
  shadedRegion;
  /** @type {HTMLCanvasElement} */
  qrCodeCanvasElement;
  /** @type {HTMLDivElement} */
  qrCodeScannerFallbackArea;

  /** @type {CanvasRenderingContext2D} */
  qrCodeCanvas;

  /** @type {boolean} */
  #paused = false;
  get paused() {
    return this.#paused;
  }
  set paused(value) {
    this.#paused = value;
    this.#onPauseStatusChangeHandler(value);
  }

  /** @type {boolean} */
  #scanInProgress = false;

  /** @type {boolean} */
  #torched = false;
  /** @type {boolean} */
  #torchSupported = false;
  /** @type {{lastChecked: number, isDarkCanvas: Array<boolean>, filter: {enabled: boolean, power?: number}}} */
  #canvasCorrectionStatus = {
    lastChecked: 0,
    isDarkCanvas: [],
    filter: { enabled: false },
  };

  /** QRコードのスキャン間隔（FPS） */
  #frameRate = 10;

  /**
   * @type {{windowOuterWidth?: number, windowOuterHeight?: number, videoWidth?: number, videoHeight?: number}}
   */
  #currentSizeContext = {};

  /**
   * @param {onScanSuccessCallback} onScanSuccessHandler
   * @param {onPauseStatusChangeCallback} onPauseStatusChangeHandler
   * @param {onVideoResizedCallback} onVideoResizedHandler
   */
  constructor(
    onScanSuccessHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  ) {
    this.onScanSuccessHandler = onScanSuccessHandler;
    this.#onPauseStatusChangeHandler = onPauseStatusChangeHandler;
    this.#onVideoResizedHandler = onVideoResizedHandler;
  }

  /**
   * @param {boolean} useBackCamera
   */
  async startScanning(useBackCamera = true) {
    if (
      !this.video ||
      !this.qrCodeScannerArea ||
      !this.previewRegion ||
      !this.shadedRegion ||
      !this.qrCodeCanvasElement
    ) {
      throw new Error(
        `Not initialized: ${this.video}, ${this.qrCodeScannerArea}, ${this.previewRegion}` +
          `, ${this.shadedRegion}, ${this.qrCodeCanvasElement}`,
      );
    }

    if (this.#scanInProgress) {
      console.error("QR code scanning has already started");
      return;
    }
    this.#scanInProgress = true;

    try {
      /** @type {MediaStreamConstraints} */
      const mediaStreamConstraints = {
        video: {
          facingMode: useBackCamera ? "environment" : "user",
          frameRate: {
            ideal: this.#frameRate,
          },
          width: {
            ideal: 2048,
          },
          height: {
            ideal: 2048,
          },
        },
      };

      console.log("Start Scanning:", mediaStreamConstraints);
      const mediaStream = await navigator.mediaDevices.getUserMedia(
        mediaStreamConstraints,
      );

      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      // @ts-ignore
      this.#torchSupported = supportedConstraints.torch === true;

      // 鏡映反転の補正を実施
      if (useBackCamera) {
        this.video.style.transform = null;
      } else {
        this.video.style.transform = "rotateY(180deg)";
      }

      this.#adjustMaxVideoHeight();

      this.video.srcObject = mediaStream;
      await this.video.play();
      this.#requestNextFrame();
      this.qrCodeScannerArea.style.display = "block";
    } catch (error) {
      if (error.name === "NotAllowedError") {
        console.log("Requested camera device cannot be used at this time.");
        this.qrCodeScannerFallbackArea.style.display = "block";
      } else {
        throw error;
      }
    }
  }

  async stopScanning() {
    if (!this.#scanInProgress) {
      return;
    }
    this.#scanInProgress = false;

    console.log("Stop Scanning");
    this.qrCodeScannerArea.style.display = "none";
    if (this.video.srcObject) {
      if (!(this.video.srcObject instanceof MediaStream)) {
        throw new TypeError(
          "video.srcObject is typeof " + typeof this.video.srcObject,
        );
      }
      this.video.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      this.video.srcObject = null;
    }

    // initialize instance fields
    this.#currentSizeContext = {};
    this.#torched = false;
    this.#canvasCorrectionStatus = {
      lastChecked: 0,
      isDarkCanvas: [],
      filter: { enabled: false },
    };
    this.paused = false;
  }

  /**
   * @param {boolean} [pause]
   */
  pauseOrResumeScanning(pause = undefined) {
    if (this.video?.srcObject instanceof MediaStream) {
      const trackEnabled = pause === undefined ? this.paused : !pause;
      this.video.srcObject.getTracks().forEach((track) => {
        track.enabled = trackEnabled;
      });
      this.paused = !trackEnabled;
      if (this.paused) {
        console.log("Pause Scanning");
      } else {
        console.log("Resume Scanning");
        this.#requestNextFrame();
      }
    }
  }

  resizeIfNeeded() {
    if (
      this.#currentSizeContext.windowOuterWidth !== window.outerWidth ||
      this.#currentSizeContext.windowOuterHeight !== window.outerHeight ||
      this.#currentSizeContext.videoWidth !== this.video.videoWidth ||
      this.#currentSizeContext.videoHeight !== this.video.videoHeight
    ) {
      // landscapeの場合は画面横幅（outerWidth）の1/2をカメラのプレビュー領域にする
      this.previewRegion.style.width =
        window.outerWidth > window.outerHeight
          ? `${Math.floor(window.outerWidth / 2)}px`
          : "100%";

      this.#adjustMaxVideoHeight();

      this.shadedRegion.style["border-width"] = `${Math.floor(
        this.previewRegion.clientHeight / 4,
      )}px ${Math.floor(this.previewRegion.clientWidth / 4)}px`;

      this.qrCodeCanvasElement.width = Math.ceil(this.video.videoWidth / 2);
      this.qrCodeCanvasElement.height = Math.ceil(
        this.#getTrimmedVideoHeight().height / 2,
      );

      this.#currentSizeContext = {
        windowOuterWidth: window.outerWidth,
        windowOuterHeight: window.outerHeight,
        videoWidth: this.video.videoWidth,
        videoHeight: this.video.videoHeight,
      };

      this.#onVideoResizedHandler();
    }
  }

  #adjustMaxVideoHeight() {
    // カメラ領域の最大高を画面縦幅（outerWidth）の3/7にする
    this.video.style.maxHeight = `${Math.floor(
      (window.outerHeight / 7) * 3,
    )}px`;
  }

  #tick() {
    if (!this.#scanInProgress || !this.video || this.paused) {
      return;
    }
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.resizeIfNeeded();

      const { height: videoHeight, offset: videoHeightOffset } =
        this.#getTrimmedVideoHeight();
      this.qrCodeCanvas.drawImage(
        this.video,
        Math.floor(this.video.videoWidth / 4),
        videoHeightOffset + Math.floor(videoHeight / 4),
        this.qrCodeCanvasElement.width,
        this.qrCodeCanvasElement.height,
        0,
        0,
        this.qrCodeCanvasElement.width,
        this.qrCodeCanvasElement.height,
      );

      this.onReadQrcode();
    }
    this.#requestNextFrame();
  }

  /**
   * @returns {{height: number, offset: number}}
   */
  #getTrimmedVideoHeight() {
    const trimmedVideoHeight = Math.floor(
      this.video.clientHeight *
        (this.video.videoWidth / this.video.clientWidth),
    );
    return {
      height: trimmedVideoHeight,
      offset: Math.floor((this.video.videoHeight - trimmedVideoHeight) / 2),
    };
  }

  #requestNextFrame() {
    setTimeout(() => this.#tick(), 1000 / this.#frameRate);
  }
}

export class OpenCvQrCodeScanner extends QrCodeScanner {
  /**
   * @param {onScanSuccessCallback} onScanSuccessHandler
   * @param {onPauseStatusChangeCallback} onPauseStatusChangeHandler
   * @param {onVideoResizedCallback} onVideoResizedHandler
   */
  constructor(
    onScanSuccessHandler,
    onPauseStatusChangeHandler,
    onVideoResizedHandler,
  ) {
    super(
      onScanSuccessHandler,
      onPauseStatusChangeHandler,
      onVideoResizedHandler,
    );
    this.onReadQrcode = this.readQrcode;
  }

  async initBeforeMount() {
    try {
      await initCV();
    } catch (error) {
      console.log("OpenCV initialization failed:", error);
      throw error;
    }
  }

  initAfterMount() {
    this.qrCodeCanvas = this.qrCodeCanvasElement.getContext("2d", {
      alpha: false,
      willReadFrequently: true,
    });
    this.qrCodeCanvas.imageSmoothingEnabled = false;
  }

  readQrcode() {
    // console.time("readQrcode");
    let cvImage = cv.imread(this.qrCodeCanvasElement); // Mat
    let qrRes = qrcodeDetector.detectAndDecode(cvImage); // StringVector
    if (qrRes.size() !== 0) {
      this.shadedRegion.classList.add("flash");
      for (let i = 0; i < qrRes.size(); i++) {
        this.onScanSuccessHandler(qrRes.get(i));
      }
    } else {
      this.shadedRegion.classList.remove("flash");
    }
    cvImage.delete();
    qrRes.delete();
    // console.timeEnd("readQrcode");
  }
}
