<script>
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";

  import { initAudioContext } from "~/libs/audio";

  /**
   * label: string
   * component: component
   */
  export let items = [];
  export let activeTabIndex = 0;
  export let displayTabCheck = false;

  let displayedTab = [];

  const dispatch = createEventDispatcher();

  if (displayTabCheck) {
    for (let i = 0; i < items.length; i++) {
      displayedTab[i] = i === activeTabIndex ? true : false;
    }
  }

  function chackAllTabDisplayed() {
    let allTabDisplayed = true;

    displayedTab.forEach((element) => {
      if (element === false) {
        allTabDisplayed = false;
      }
    });

    if (allTabDisplayed) {
      dispatch("checked");
    }
  }
</script>

<ul class="tabs">
  {#each items as item, index}
    <li class="tab {index === activeTabIndex ? 'active' : ''}">
      <button
        type="button"
        class="tab__inner"
        on:click={() => {
          activeTabIndex = index;
          initAudioContext();

          displayedTab[activeTabIndex] = true;
          chackAllTabDisplayed();
        }}>{item.label}</button
      >
    </li>
  {/each}
</ul>
{#each items as item, index}
  {#if index == activeTabIndex}
    <div class="tab-contents" in:fade>
      <svelte:component this={item.component} />
    </div>
  {/if}
{/each}

<style>
  .tabs {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    padding: 0 4px;
    width: 100%;
  }
  .tab {
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    list-style: none;
    margin: 0 4px 4px;
    text-align: center;
    width: calc((100% / 2) - 8px);
  }
  .tab.active {
    background-color: #ededed;
    font-weight: bold;
    margin-bottom: 0;
  }
  .tab .tab__inner {
    background-color: transparent;
    border: none;
    display: block;
    font-weight: inherit;
    padding: 10px 0 6px;
    height: 100%;
    width: 100%;
  }
  .tab.tab.active .tab__inner {
    padding-bottom: 10px;
  }
  .tab-contents {
    padding: 24px 4px;
  }
</style>
