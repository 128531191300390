<script>
  import Router from "svelte-spa-router";
  import { useRegisterSW } from "virtual:pwa-register/svelte";

  import NfcTagScan1 from "~/pages/NfcTagScan1.svelte";
  import NfcTagScan2 from "~/pages/NfcTagScan2.svelte";
  import NfcTagScan3 from "~/pages/NfcTagScan3.svelte";

  const routes = {
    "/": NfcTagScan1,
    "/result/:mockId": NfcTagScan2,
    "/complete": NfcTagScan3,
    "*": NfcTagScan1,
  };

  if (import.meta.env.PROD) {
    useRegisterSW({ immediate: true });
  }
</script>

<Router {routes} />
