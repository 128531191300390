export let helper = [
  {
    value: "1",
    label: "介助者が同行している",
    checked: false,
  },
  {
    value: "2",
    label: "介助者が同行していない",
    checked: false,
  },
  {
    value: "3",
    label: "介助者が同行しているが、支援を要する",
    checked: false,
  },
];

export let equipment = [
  {
    value: "1",
    label: "椅子を要する",
    checked: false,
  },
  {
    value: "2",
    label: "簡易ベッドを要する",
    checked: false,
  },
  {
    value: "3",
    label: "不要",
    checked: false,
  },
];
